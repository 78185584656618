import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/adesa-eu-styles.less';
import './styles/index.less';
import './styles/flags.less';
import './styles/cotw-icons.less';
import './styles/batchlogo.sprite.less';

import '@ambiorix/adesa-web-kit/dist/index.css';
import '@ambiorix/adesa-web-kit/dist/global.css';

import 'core-js/features/array/flat'; // missing in Edge.
import 'core-js/features/array/every';

import { trackUtmCodes } from './utils/utm';

import { render } from 'react-dom';
import React, { useState, useEffect } from 'react';
import { Provider, connect } from 'react-redux';
import { combineReducers, createStore, compose, applyMiddleware, bindActionCreators } from 'redux';
import thunk from 'redux-thunk';
import { showLoginDialog, isWebViewInMobileApp } from './utils';

import { commonReducer, commonMapStateToProps, commonActionsCreator } from './state/Common';
import { homeReducer, homeMapStateToProps, homeActionsCreator } from './state/Home';
import { carSearchReducer, carSearchMapStateToProps, carSearchActionsCreator } from './state/CarSearch';
import { carDetailReducer, carDetailMapStateToProps, carDetailActionsCreator } from './state/CarDetail';
import { auctionBatchesReducer, auctionBatchesMapStateToProps, auctionBatchesActionsCreator } from './state/AuctionBatches';
import { sessionReducer, sessionMapStateToProps, sessionExpirationActionsCreator } from './state/SessionExpiration';
import { myBidsReducer, myBidsMapStateToProps, myBidsActionsCreator } from './state/MyBids';
import { xTimeDashboardReducer, xTimeDashboardMapStateToProps, xTimeDashboardActionsCreator } from './state/XTimeDashboard';
import { orderCompletionReducer, orderCompletionMapStateToProps, orderCompletionActionsCreator } from './state/OrderCompletion';
import { communitiesReducer, communitiesMapStateToProps, communitiesActionsCreator } from './state/Communities';
import { myAccountReducer, myAccountMapStateToProps, myAccountActionsCreator } from './state/MyAccount';
import { registerReducer, registerMapStateToProps, registerActionsCreator } from './state/Register';
import { loginReducer, loginMapStateToProps, loginActionsCreator } from './state/Login';

import { SignalrClientOld } from './state/signalr-old';
import { SignalrClient } from './state/signalr';

import SessionExpiration from './components/Session/sessionExpiration';
import { TopNavigation } from './components/Layout/TopNavigation';
import OldCotwFooter from './components/Layout/MainFooter';

import MyAccount from './views/MyAccount/MyAccount';
import RegistrationForm from './views/RegistrationForm';
import MyAccountPurchases from './views/MyAccount/MyAccountPurchases';
import CarReport from './views/CarReport';
import DamageReport from './views/DamageReport';
import CommunityInvite from './views/CommunityInvite';
import MyAccountOrders from './views/MyAccount/MyAccountOrders';
import AccountConfirmationScreen from './views/AccountConfirmationPage';
import NotificationsSystem, { notify, reducer as notificationsReducer } from 'reapop';
import theme from 'reapop-theme-wybo';

import settings, { loadSettings } from './appsettings';
import { useAppSettings } from './hooks/useAppSettings';

import i18n from './i18n';
import { I18nextProvider, withNamespaces } from 'react-i18next';

import { createBrowserHistory } from 'history';
import { Route, Switch } from 'react-router-dom';

import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import CookieConsent from './components/ui/CookieConsent';

import { Test, T1, T2, T3 } from './index-demo';
import CotwHead from './components/Layout/CotwHead';
import { seoPaths } from './routing';
import PrivacyNotice from './views/PrivacyNotice';

import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { WebKitProvider } from '@ambiorix/adesa-web-kit';
import { FullScreenSpinner } from './components/ui/FullScreenSpinner';

import {
    AuctionBatches,
    CarDetail,
    CarSearch,
    Communities,
    Home,
    LoginOkta,
    LoginOktaCallback,
    MyBids,
    MySelectionView,
    OrderCompletion,
    RegisterPage,
    ResetOktaPassword,
    XTimeDashboard,
} from './lazy-router';

import styles from './index.module.scss';
import { DESKTOP_BREAKPOINT } from './hooks/useAtleastMDMediaQuery';

// --- Configuration Notification component (reapop)
//  https://github.com/LouisBarranqueiro/reapop/blob/master/docs/api.md#customize-default-values-for-notifications

const notificationsConfig = {
    status: 'info',
    position: 'tc',
    dismissible: true,
    dismissAfter: 4000,
    allowHTML: true,
    closeButton: true,
};

// --- initialize browser history.

const history = createBrowserHistory();
window.appHistory = history;

// ---  Redux store creation.

const middlewares = [thunk, routerMiddleware(history)];

const rootReducer = combineReducers({
    common: commonReducer,
    home: homeReducer,
    carSearch: carSearchReducer,
    carDetail: carDetailReducer,
    myBids: myBidsReducer,
    xTimeDashboard: xTimeDashboardReducer,
    orderCompletion: orderCompletionReducer,
    auctionBatches: auctionBatchesReducer,
    communities: communitiesReducer,
    myAccount: myAccountReducer,
    notifications: notificationsReducer(notificationsConfig),
    router: connectRouter(history),
    session: sessionReducer,
    register: registerReducer,
    login: loginReducer,
});

const enhancers = [];
const devToolsExtension = '__REDUX_DEVTOOLS_EXTENSION__'; // redux chrome extension integration.
if (window[devToolsExtension]) enhancers.push(window[devToolsExtension]());

const store = createStore(rootReducer, compose(applyMiddleware(...middlewares), ...enhancers));

// --- Link components to redux state.
const ConnectedHome = connect(
    state => ({
        ...commonMapStateToProps(state),
        ...homeMapStateToProps(state),
        ...carSearchMapStateToProps(state),
    }),
    {
        notify,
        ...commonActionsCreator,
        ...homeActionsCreator,
        onChangeSearchSelection: carSearchActionsCreator.onChangeSearchSelection,
    },
)(Home);
const ConnectedCarSearch = connect(state => ({ ...commonMapStateToProps(state), ...carSearchMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...carSearchActionsCreator,
})(CarSearch);
const ConnectedCarDetail = connect(state => ({ ...commonMapStateToProps(state), ...carDetailMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...carDetailActionsCreator,
})(CarDetail);
const ConnectedAuctionBatches = connect(state => ({ ...commonMapStateToProps(state), ...auctionBatchesMapStateToProps(state) }), {
    notify,
    ...auctionBatchesActionsCreator,
    onShowAuctionBatch: carSearchActionsCreator.onShowAuctionBatch,
})(AuctionBatches);
const ConnectedMyBids = connect(state => ({ ...commonMapStateToProps(state), ...myBidsMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...myBidsActionsCreator,
})(MyBids);
const ConnectedXTimeDashboard = connect(state => ({ ...commonMapStateToProps(state), ...xTimeDashboardMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...xTimeDashboardActionsCreator,
})(XTimeDashboard);
const ConnectedOrderCompletion = connect(state => ({ ...commonMapStateToProps(state), ...orderCompletionMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...orderCompletionActionsCreator,
})(OrderCompletion);
const ConnectedCarReport = connect(state => ({ ...commonMapStateToProps(state), ...carDetailMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...carDetailActionsCreator,
})(CarReport);
const ConnectedDamageReport = connect(state => ({ ...commonMapStateToProps(state), ...carDetailMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...carDetailActionsCreator,
})(DamageReport);
const ConnectedCommunties = connect(state => ({ ...commonMapStateToProps(state), ...communitiesMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...communitiesActionsCreator,
    onShowCommunityAuctions: carSearchActionsCreator.onShowCommunityAuctions,
})(Communities);
const ConnectedCommunityInvite = connect(state => ({ ...commonMapStateToProps(state), ...communitiesMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...communitiesActionsCreator,
})(CommunityInvite);
const ConnectedMyAccountOrders = connect(state => ({ ...commonMapStateToProps(state), ...myAccountMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...myAccountActionsCreator,
})(MyAccountOrders);
const ConnectedRegister = connect(state => ({ ...commonMapStateToProps(state), ...registerMapStateToProps(state), ...loginMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...registerActionsCreator,
    ...loginActionsCreator,
})(RegisterPage);
const ConnectedMySelectionView = connect(
    state => ({
        ...commonMapStateToProps(state),
        ...homeMapStateToProps(state),
    }),
    {
        notify,
        ...commonActionsCreator,
        ...homeActionsCreator,
    },
)(MySelectionView);

// header is used on each page, so cannot be connected to a specific stateToProps map.
const ConnectedOldCotwFooter = connect(commonMapStateToProps)(OldCotwFooter);
const ConnectedTopNavigation = connect(state => ({ ...commonMapStateToProps(state), ...loginMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...loginActionsCreator,
})(TopNavigation);
const ConnectedCotwHead = connect(commonMapStateToProps)(CotwHead);
const ConnectedSessionExpiration = connect(sessionMapStateToProps, sessionExpirationActionsCreator)(SessionExpiration);
const ConnectedMyAccount = connect(commonMapStateToProps, commonActionsCreator)(MyAccount);
const ConnectedRegistrationForm = connect(commonMapStateToProps, commonActionsCreator)(RegistrationForm);
const ConnectedMyAccountPurchases = connect(commonMapStateToProps, commonActionsCreator)(MyAccountPurchases);
const ConnectedLoginOktaCallback = connect(state => ({ ...commonMapStateToProps(state), ...loginMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...loginActionsCreator,
})(LoginOktaCallback);
const ConnectedLoginOkta = connect(loginMapStateToProps, loginActionsCreator)(LoginOkta);
const ConnectedResetOktaPassword = connect(
    state => ({ ...commonMapStateToProps(state), ...loginMapStateToProps(state) }),
    commonActionsCreator,
)(ResetOktaPassword);

const ConnectedAccountConfirmation = connect(state => ({ ...commonMapStateToProps(state), ...loginMapStateToProps(state) }), {
    notify,
    ...commonActionsCreator,
    ...loginActionsCreator,
})(AccountConfirmationScreen);
// --- Link components to i18next library (translations)
const TranslatedConnectedHome = withNamespaces()(ConnectedHome);
const TranslatedConnectedCarDetail = withNamespaces()(ConnectedCarDetail);
const TranslatedConnectedTopNavigation = withNamespaces()(ConnectedTopNavigation);
const TranslatedTopNavigation = withNamespaces()(ConnectedTopNavigation);
const TranslatedConnectedOldCotwFooter = withNamespaces()(ConnectedOldCotwFooter);
const TranslatedConnectedCarSearch = withNamespaces()(ConnectedCarSearch);
const TranslatedConnectedAuctionBatches = withNamespaces()(ConnectedAuctionBatches);
const TranslatedCookieConsent = withNamespaces()(CookieConsent);
const TranslatedConnectedSessionExpiration = withNamespaces()(ConnectedSessionExpiration);
const TranslatedConnectedMyBids = withNamespaces()(ConnectedMyBids);
const TranslatedConnectedXTimeDashboard = withNamespaces()(ConnectedXTimeDashboard);
const TranslatedConnectedOrderCompletion = withNamespaces()(ConnectedOrderCompletion);
const TranslatedConnectedCotwHead = withNamespaces()(ConnectedCotwHead);
const TranslatedPrivacyNotice = withNamespaces()(PrivacyNotice);
const TranslatedConnectedMyAccount = withNamespaces()(ConnectedMyAccount);
const TranslatedConnectedRegistrationForm = withNamespaces()(ConnectedRegistrationForm);
const TranslatedConnectedMyAccountPurchases = withNamespaces()(ConnectedMyAccountPurchases);
const TranslatedConnectedCarReport = withNamespaces()(ConnectedCarReport);
const TranslatedConnectedDamageReport = withNamespaces()(ConnectedDamageReport);
const TranslatedConnectedCommunties = withNamespaces()(ConnectedCommunties);
const TranslatedConnectedCommunityInvite = withNamespaces()(ConnectedCommunityInvite);
const TranslatedConnectedMyAccountOrders = withNamespaces()(ConnectedMyAccountOrders);
const TranslatedConnectedRegister = withNamespaces()(ConnectedRegister);
const TranslatedConnectedLoginOktaCallback = withNamespaces()(ConnectedLoginOktaCallback);
const TranslatedConnectedLoginOkta = withNamespaces()(ConnectedLoginOkta);
const TranslatedResetOktaPassword = withNamespaces()(ConnectedResetOktaPassword);
const TranslatedConnectedMySelectionView = withNamespaces()(ConnectedMySelectionView);
const TranslatedAccountConfirmation = withNamespaces()(ConnectedAccountConfirmation);

// To allow page transition using fading.
// cfr https://medium.com/@khwsc1/step-by-step-guide-of-simple-routing-transition-effect-for-react-with-react-router-v4-and-9152db1566a0
const SwitchWithTransition = ({ children }) => (
    <Route
        render={({ location }) => (
            <TransitionGroup className="page-transition-group">
                <CSSTransition key={location.key} classNames="fade" timeout={400} className="page-transition">
                    <div>
                        <Switch location={location}>{children}</Switch>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        )}
    />
);

// all paths for which the header/footer should be displayed.
const displayHeaderFooterPaths = [
    '/*/homev6/privacy-policy',
    '/*/home',
    '/*/findcar',
    '/*/findcar/filter', // not visible, but we also don't want the navbar to unmount (prevent banner from moving above navbar)
    '/*/findcar/saved',
    '/*/findcar/saved/*',
    '/*/findcar/Advanced',
    '/*/auctions',
    '/*/my-account/*',
    '/*/my-recommendations',
    '/*/*/registration',
    '/*/*/purchases',
    '/*/communities',
    '*/ProcessOgonePayment/*',
    '/*/*/biddingdashboard',
    '/*/*/biddingdashboard/*',
    '/*/*/extendedbatch',
    '/*/*/extendedbatch/*',
    ...seoPaths,
    ...(isWebViewInMobileApp ? [] : ['/*/car/info', '/*/auctionprocess/index']), // hide header/footer when cardetail/completion pages are shown on mobile app.
];

const noTopNavigationPaths = ['/*/register'];
const displayHeader = displayHeaderFooterPaths.concat(noTopNavigationPaths);

const displayFooterPaths = displayHeaderFooterPaths.filter(path => !['/*/auctionprocess/index'].includes(path));

// --- OKTA config

//const oktaAuth = new OktaAuth(config.oidc);
let oktaAuth = null; // oktaAuth initialization deffered because we have to wait the load of settings.
const customAuthHandler = () => history.push('/login/okta');
const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const redirectUri = _oktaAuth.options.redirectUri;
    if (redirectUri) {
        history.replace(toRelativeUrl(redirectUri, window.location.origin));
    } else {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    }
};

const ConnectedSecurity = connect(loginMapStateToProps)(({ children, isOktaInitialized }) =>
    isOktaInitialized ? (
        <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    ) : (
        children
    ),
);

const App = () => {
    const appSettings = useAppSettings();

    const appTheme = 'openlane';

    return (
        <WebKitProvider
            config={{
                breakpoints: {
                    desktop: DESKTOP_BREAKPOINT,
                },
                theme: appTheme,
            }}>
            <div className={styles.appRoot}>
                {appSettings.isLoading && <FullScreenSpinner />}
                <Provider store={store}>
                    <I18nextProvider i18n={i18n}>
                        <ConnectedRouter history={history}>
                            <ConnectedSecurity>
                                <Route path={displayHeaderFooterPaths} exact component={TranslatedConnectedTopNavigation} />
                                <Route path={displayHeaderFooterPaths} exact component={TranslatedConnectedSessionExpiration} />
                                <Route path={displayHeader} exact component={TranslatedConnectedCotwHead} />

                                <Switch>
                                    <Route path="/*/homev6/privacy-policy" component={TranslatedPrivacyNotice} />
                                    <Route path="/*/home" component={TranslatedConnectedHome} />
                                    <Route path="/*/findcar" component={TranslatedConnectedCarSearch} />
                                    <Route path="/*/car/info" component={TranslatedConnectedCarDetail} />
                                    <Route path="/*/auctions" component={TranslatedConnectedAuctionBatches} />
                                    <Route path="/*/auctionprocess" component={TranslatedConnectedOrderCompletion} />
                                    <Route path="/*/*/ordercompletion" component={TranslatedConnectedOrderCompletion} />
                                    <Route path="/*/*/biddingdashboard" component={TranslatedConnectedMyBids} />
                                    <Route path="/*/*/extendedbatch" component={TranslatedConnectedXTimeDashboard} />
                                    <Route path="/*/footer" component={TranslatedConnectedOldCotwFooter} /> {/* to be used in CMS */}
                                    <Route path={seoPaths} component={TranslatedConnectedCarSearch} />
                                    <Route path="/*/my-account/community-orders" component={TranslatedConnectedMyAccountOrders} />
                                    <Route path="/*/my-account" component={TranslatedConnectedMyAccount} />
                                    <Route path="/*/my-recommendations" component={TranslatedConnectedMySelectionView} />
                                    <Route path="/*/*/registration" component={TranslatedConnectedRegistrationForm} />
                                    <Route path="/*/*/purchases" component={TranslatedConnectedMyAccountPurchases} />
                                    <Route path="/*/*/carreport" component={TranslatedConnectedCarReport} />
                                    <Route path="/*/*/damagereport" component={TranslatedConnectedDamageReport} />
                                    <Route path="/*/communities" component={TranslatedConnectedCommunties} />
                                    <Route path="/*/communityInvite" component={TranslatedConnectedCommunityInvite} />
                                    <Route path="/*/register" component={TranslatedConnectedRegister} />
                                    <Route path="/*/login/okta" component={TranslatedConnectedLoginOkta} />
                                    <Route path="/login/callback" component={TranslatedConnectedLoginOktaCallback} />
                                    <Route path="/*/reset-okta-password" component={TranslatedResetOktaPassword} />
                                    <Route path="/*/signup/verifyemail" component={TranslatedAccountConfirmation} />
                                </Switch>

                                <Route path={displayFooterPaths} exact component={TranslatedConnectedOldCotwFooter} />
                            </ConnectedSecurity>
                            <NotificationsSystem theme={theme} />
                        </ConnectedRouter>
                    </I18nextProvider>
                </Provider>
            </div>
        </WebKitProvider>
    );
};
render(<App />, document.getElementById('react-root'));

// -------- Mocking requests... (FOR TEST ONLY!!) ----------
if (location.host === 'testcotwcarsite' || location.host === 'localhost:8080') {
    // returns predifined response when on a test environment.
    window.__FETCH_MOCK__ = {}; // cfr utils.js, fetchJson function
    //window.__FETCH_MOCK__['https://testcotwcarsite/en/homev6/recommendedauctions?template=Country'] = [{ "auctionId": 3542540, "reference": "45862031-1", "thumbnailUrl": "https://images.adesa.eu/carimgs/WAUZZZ8K7BA073108/general/IMG_9360.jpg", "tagLine": "Audi A4 2.0 TDI (2008) - Diesel - Automatic - 155.236 km", "mileage": 155236, "registrationDate": "29/11/2010", "currentPrice": 4500, "buyNowPrice": -1, "recommendedPrice": 4700, "isClosed": true, "isBuyNow": false }];
    //window.__FETCH_MOCK__['https://testcotwcarsite/en/homev6/recommendedauctions?template=Activity'] = [{ "auctionId": 3542540, "reference": "45862031-1", "thumbnailUrl": "https://images.adesa.eu/carimgs/WAUZZZ8K7BA073108/general/IMG_9360.jpg", "tagLine": "Audi A4 2.0 TDI (2008) - Diesel - Automatic - 155.236 km", "mileage": 155236, "registrationDate": "29/11/2010", "currentPrice": 4500, "buyNowPrice": -1, "recommendedPrice": 4700, "isClosed": true, "isBuyNow": false }];
    //window.__FETCH_MOCK__['https://testcotwcarsite/en/carv6/auction/5680167'] = require('./state/demoData/auction.json');
    //window.__FETCH_MOCK__['https://testcotwcarsite/en/mybidsv6/activeauctions'] = require('./state/demoData/mybids_active.json');
    //window.__FETCH_MOCK__['https://testcotwcarsite/en/mybidsv6/closedauctions'] = require('./state/demoData/mybids_closed.json');
    //window.__FETCH_MOCK__['https://testcotwcarsite/en/mybidsv6/pendingauctions'] = require('./state/demoData/mybids_pending.json');
    //window.__FETCH_MOCK__['https://testcotwcarsite/en/homev6/ImportantCountryMessages?countryId=be&ecadisCountryId=6'] = require('./state/demoData/importantCountryMessages.json');
    //window.__FETCH_MOCK__['https://testcotwcarsite/en/carv6/damages?auctionId=3567367'] = require('./state/demoData/damages.json');
    //window.__FETCH_MOCK__['https://testcotwcarsite/fi/carv6/SellerExtraInfo?auctionId=5680031'] = require('./state/demoData/SellerExtraInfo.json');
}

const commonActions = bindActionCreators(commonActionsCreator, store.dispatch);
const loginActions = bindActionCreators(loginActionsCreator, store.dispatch);
const carSearchActions = bindActionCreators(carSearchActionsCreator, store.dispatch);
const homeActions = bindActionCreators(homeActionsCreator, store.dispatch);
const auctionBatchesActions = bindActionCreators(auctionBatchesActionsCreator, store.dispatch);
const carDetailActions = bindActionCreators(carDetailActionsCreator, store.dispatch);
const myBidsActions = bindActionCreators(myBidsActionsCreator, store.dispatch);
const xTimeDashboardActions = bindActionCreators(xTimeDashboardActionsCreator, store.dispatch);
const orderCompletionActions = bindActionCreators(orderCompletionActionsCreator, store.dispatch);
const sessionActions = bindActionCreators(sessionExpirationActionsCreator, store.dispatch);
const communitiesActions = bindActionCreators(communitiesActionsCreator, store.dispatch);
const myAccountActions = bindActionCreators(myAccountActionsCreator, store.dispatch);

const isHomePage = () => /\/home\b/i.test(location.pathname);
const isCarDetailPage = () => /\/car\/info\b/i.test(location.pathname);
const isMyBidsPage = () => /\/biddingdashboard/i.test(location.pathname);
const isAuctionBatchesPage = () => /\/auctions\b/i.test(location.pathname);
const isOrderCompletionPage = () => /\/auctionprocess\b/i.test(location.pathname);
const isXTimeDashboardPage = () => /\/extendedbatch/i.test(location.pathname);
const isCommunitiesPage = () => /\/communities/i.test(location.pathname);
const isCommunityOrdersPage = () => /my-account\/community-orders/i.test(location.pathname);
const isCommunityInvitePage = () => /\/communityInvite/i.test(location.pathname);
const isCarReportPage = () => /\/carv6\/(car|damage)report\b/i.test(location.pathname);
const isAdvancedSearchPage = () => /\/findcar\/advanced\/?$/i.test(location.pathname);
const isSearchResultsPage = () => /\/findcar\/?$/i.test(location.pathname);
const isSavedSearchPage = () => /\/findcar\/saved\/?$/i.test(location.pathname);
const isMySelectionPage = () => /\/my-recommendations\b/i.test(location.pathname);

// Maps signalr events to redux actions.
const signalrEvents = {
    addAuctionWatchForClient: auctionData => {
        myBidsActions.onAddAuctionWatchForClient(auctionData);
    },
    removeBidWatchForClient: auctionData => {
        myBidsActions.onRemoveBidWatchForClient(auctionData);
    },
    addAuctionBidForClient: auctionData => {
        carDetailActions.onAddAuctionBidForClient(auctionData);
        carSearchActions.onAddAuctionBidForClient(auctionData);
        myBidsActions.onAddAuctionBidForClient(auctionData);
        xTimeDashboardActions.onAddAuctionBidForClient(auctionData);
    },
    auctionExtended: auctionData => {
        carDetailActions.onAuctionExtended(auctionData);
        carSearchActions.onAuctionExtended(auctionData);
        myBidsActions.onAuctionExtended(auctionData);
        xTimeDashboardActions.onAuctionExtended(auctionData);
    },
    xTimePendingMessage: auctionData => {
        myBidsActions.onXTimePendingMessage(auctionData);
        xTimeDashboardActions.onXTimePendingMessage(auctionData);
    },
    auctionBatchExtended: auctionData => {
        carDetailActions.onAuctionBatchExtended(auctionData);
    },
    xTimeAuctionFinished: auctionData => {
        carDetailActions.onXTimeAuctionFinished(auctionData);
        carSearchActions.onXTimeAuctionFinished(auctionData);
        myBidsActions.onXTimeAuctionFinished(auctionData);
        xTimeDashboardActions.onXTimeAuctionFinished(auctionData);
    },
    xTimeAuctionBatchEnd: auctionData => {
        carDetailActions.onXTimeAuctionBatchEnd(auctionData);
    },
    xTimeMessage: auctionData => {
        // Message received during Alphabet auction to put an auction in xTime dashboard.
        //[{AuctionBatchId: 122538, AuctionId: 3453723, CurrentPrice: 6300, BidOptions: [6400], "EndDateExtendedPhase": "2019-11-18T11:08:00"}]
        xTimeDashboardActions.onXTimeMessage(auctionData);
        myBidsActions.onXTimeMessage(auctionData);
        carDetailActions.onXTimeMessage(auctionData); // update end-date
        carSearchActions.onXTimeMessage(auctionData); // update end-date
    },
    broadcastServerTime: dateTime => {
        // dateTime received is Belgium local time !!   => UTC
        commonActions.onServerTimeSync(dateTime);
    },
    ultimoStarted: () => {
        if (isMyBidsPage()) myBidsActions.onRefreshState();
        if (isCarDetailPage()) {
            const id = matched(/\?auctionid=(\d+)/i, location.search, i => parseInt(i, 10));
            carDetailActions.onShowAuction(id);
        }
    },
    provisionalStateChanged: (updatedAuctionId, newState, amount) => {
        // newState= Start, AcceptSeller, AcceptBuyer, RejectSeller, RejectBuyer, CounterOfferSeller, CounterOfferBuyer, StandOnOfferSeller, StandOnOfferBuyer, End
        if (isCarDetailPage()) {
            carDetailActions.onRefreshAuction(updatedAuctionId);
        }
        if (isMyBidsPage()) {
            myBidsActions.onRefreshPendingAuctions(updatedAuctionId, newState);
        }
    },
    orderDocumentReady: auctionId => {
        orderCompletionActions.onGetOrderDocuments(auctionId);
    },
};

const matched = (re, text, conv = i => i) => {
    const match = new RegExp(re, 'i').exec(text);
    return match && conv(match[1]);
};

const matchedId = (re, text) => {
    const match = matched(re, text);
    return match ? parseInt(match, 10) : 0;
};

/**
 * Initiate redux state according to current context (current page URL).
 *
 */
loadSettings().then(() => {
    oktaAuth = new OktaAuth(settings.oidc); // Okta init after load of okta settings.
    loginActions.onOktaInitialized();
    const { isUserLoggedIn, currentLanguage, contactId } = window; // Cfr _LayoutV6.cshtml (in carsite)
    const init = Promise.all([
        initSignalr(contactId, signalrEvents, () => commonActions.onSetWebSocketInError()),
        commonActions.onInit(isUserLoggedIn, currentLanguage || 'en', contactId || 0),
    ]);
    init.then(([signalrClient]) => {
        sessionActions.onInit();
        carDetailActions.onInit(signalrClient);
        const communityId = matched(/\?communityid=(\d+)/i, location.search, i => parseInt(i, 10));
        const searchInitialized = carSearchActions.onInit(signalrClient, communityId);
        if (isCommunitiesPage()) {
            communitiesActions.onInit();
        }
        if (isCommunityInvitePage()) {
            communitiesActions.fetchCommunityInvite();
        }
        if (isHomePage()) {
            searchInitialized.then(() => {
                carSearchActions.onSearch(); // to fill quick search.
                homeActions.onInit();
            });
        }
        if (isMySelectionPage()) {
            homeActions.onFetchMySelection();
        }
        if (isCarDetailPage() || isCarReportPage()) {
            const id = matched(/\?auctionid=(\d+)/i, location.search, i => parseInt(i, 10));
            const ref = matched(/&ref=(.*)$/i, location.search);
            carDetailActions.onShowAuction(id, ref).then(() => {
                if (isCarReportPage()) {
                    // force load of damages, to be used in damage report.
                    carDetailActions.onSelectDetailTab(2);
                }
            });
        }
        if (isOrderCompletionPage()) {
            const id = matched(/\?auctionid=(\d+)/i, location.search, i => parseInt(i, 10));
            orderCompletionActions.onInit(id);
        }
        if (/\/processogonepayment\b/i.test(location.pathname)) {
            // redirect coming from ogone/ingenico payment page
            const id = matched(/processogonepayment\/(\d+)/i, location.pathname, i => parseInt(i, 10));
            orderCompletionActions.onInitAfterPayment(id);
        }
        if (isAuctionBatchesPage()) {
            auctionBatchesActions.onInit();
        }
        if (/\/findcar\/saved\b/i.test(location.pathname)) {
            // check if id is specified in URL, then automatically select the saved query to show.
            // url used by saved search sent by email, search id in path as 2nd option.
            const id = matchedId(/\?id=(\d+)/i, location.search) || matchedId(/\/(\d+)$/, location.pathname);
            if (id) {
                searchInitialized.then(() => carSearchActions.onShowSavedSearch(id));
            } else {
                searchInitialized.then(() => carSearchActions.onShowSavedSearches());
            }
        }
        myBidsActions.onInit(signalrClient);
        if (isMyBidsPage()) {
            myBidsActions.onShow();
        }
        if (isXTimeDashboardPage()) {
            xTimeDashboardActions.onInit(signalrClient);
        }
        if (isCommunityOrdersPage()) {
            myAccountActions.onFetchCommunityOrders();
        }

        // Sometimes, when accessing a MyCOTW page, a redirect to the home page is done to force the login.
        // cfr NewHome.cshtml (flag from user session to force display of the login form)
        const showLoginModal = localStorage.getItem('showLoginModal');
        if (window.ForceLogin || showLoginModal) {
            showLoginDialog();
        }
        if (showLoginModal) {
            setTimeout(() => {
                localStorage.removeItem('showLoginModal');
            }, 3000);
        }
    });
    trackUtmCodes(contactId || 0);
});

// switch language (if not already the case)
if (window.userLanguage && window.userLanguage !== window.currentLanguage) {
    //window.location = location.pathname.replace(/^\/[^/]+/, "/" + window.userLanguage);
}

history.listen((location, action) => {
    if (!location.key) return; // listen only to history changes triggered by app.
    console.log('---- HISTORY --------------------------------------------');
    console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
    console.log(`The last navigation action was ${action}`);

    if (isAdvancedSearchPage() && action === 'PUSH') {
        carSearchActions.onClearSearchSelection();
    }
    if (isSearchResultsPage() && (action === 'POP' || action === 'PUSH')) {
        carSearchActions.onBackToSearchPage();
    }
    if (isAuctionBatchesPage()) {
        auctionBatchesActions.onInit();
        carSearchActions.onResetCriteria();
    }
    if (isCommunityInvitePage()) {
        communitiesActions.fetchCommunityInvite();
    }
    if (isCommunitiesPage()) {
        communitiesActions.onInit();
        carSearchActions.onResetCriteria();
    }
    if (isHomePage()) {
        homeActions.onInit();
        carSearchActions.onResetCriteria();
        carSearchActions.onSearch();
    }
    if (isMySelectionPage()) {
        homeActions.onFetchMySelection();
    }
    if (isCarDetailPage()) {
        const id = matchedId(/\?auctionid=(\d+)/i, location.search);
        const ref = matched(/&ref=(.*)$/i, location.search);
        if (id) {
            carDetailActions.onShowAuction(id, ref);
        }
    }
    if (isMyBidsPage()) {
        myBidsActions.onShow();
        carSearchActions.onResetCriteria();
    }
    if (isSavedSearchPage()) {
        carSearchActions.onShowSavedSearches();
    }
    if (isCommunityOrdersPage()) {
        myAccountActions.onFetchCommunityOrders();
    }
});

function initSignalr(contactId, signalrEvents, onDisconnected) {
    const signalrClient = new SignalrClient();
    signalrClient.handleEvents(signalrEvents);
    return contactId > 0
        ? signalrClient.connect().then(() => {
              signalrClient.registerForContact(contactId);
              signalrClient.registerForSession(contactId);
              return Promise.resolve(signalrClient);
          })
        : Promise.resolve(signalrClient);
}

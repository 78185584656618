import { pad } from '../../utils';
import { belgiumDateTimeToLocal, getCurrentDate } from '../../utils/date';
import { differenceInMilliseconds, formatDate, parseDate } from '../../utils/date';
import i18n from '../../i18n';
import { PAYMENT_STATUS } from '../cotw';

export const transportPriceDetails = (displayPrice, priceLines, rawReduction) => {
    const reduction = getTotalReductionOfPriceLines(priceLines);
    const price = getTotalOfPriceLines(priceLines);
    const savedPrice = price - displayPrice;
    const returnObj = {
        price, // initial Price without reduction, sum of pricelines
        reduction, // reduction
        reducedPrice: displayPrice, // displayprice
        savedPrice, // Amount saved on initial price,
        priceLines,
    };
    return returnObj;
};

/***************************************************************************************************************
 *  DATA - REDUX STATE MAPPINGS.
 */

export function auctionToState(auction, priceInfo, currentLanguage) {
    const currentPrice = priceInfo.CurrentPrice;
    const newBasePrice = priceInfo.NewBasePrice;
    const countryCost = priceInfo.ShowCountryCost ? priceInfo.CountryCost : 0;
    const auctionFee = priceInfo.ShowAuctionFee ? priceInfo.AuctionFee : 0;
    const auctionFeeDiscount = priceInfo.ShowAuctionFeeDiscount ? priceInfo.AuctionFeeDiscount : 0;
    const auctionFeeVat = priceInfo.ShowAuctionFeeVat ? priceInfo.AuctionFeeVat : 0;
    const mechanicalIndicatorFee = priceInfo.ShowMechanicalIndicatorFee ? priceInfo.MechanicalIndicatorFee : null;
    const mechanicalIndicatorFeeVat = priceInfo.ShowMechanicalIndicatorFeeVat ? priceInfo.MechanicalIndicatorFeeVat : null;
    const sellerSpecificFee = priceInfo.ShowSellerSpecificFee ? priceInfo.SellerSpecificFee : 0;
    const dealerDiscount = priceInfo.ShowDealerDiscounts ? priceInfo.DealerDiscount : 0;
    const bpmFee = priceInfo.ShowBpm ? priceInfo.BpmAmount : 0;
    const countryCostDiscount = 0;
    const allFees = countryCost + auctionFee + sellerSpecificFee + bpmFee + countryCostDiscount;
    const specificFees = auctionFee + sellerSpecificFee + bpmFee;
    const vehicleCost = currentPrice + allFees;
    const subTotalPrice = priceInfo.SubTotal;
    const totalPrice = priceInfo.TotalPrice;

    const vatAmount = priceInfo.ShowAuctionFee ? priceInfo.VatAmount : 0;

    return {
        auctionId: auction.auctionId,
        title: auction.carTitleMobileList[currentLanguage] ? auction.carTitleMobileList[currentLanguage] : auction.carTitleMobileList['en'],

        thumbnailUrl: auction.thumbnailUrl || '/v6/images/car-placeholder.png',

        hasTechnicalDamage: auction.hasTechnicalDamage, // auction.hasTechnicalDamage,
        hasBodyDamage: auction.isBroken,
        isDamaged: auction.hasTechnicalDamage || auction.isBroken,

        // price details
        currentPrice,
        newBasePrice,
        countryCost,
        auctionFee,
        mechanicalIndicatorFee,
        auctionFeeDiscount,
        auctionFeeVat,
        mechanicalIndicatorFeeVat,
        sellerSpecificFee,
        dealerDiscount,
        countryCostDiscount,
        bpmFee,
        allFees,
        specificFees,
        vehicleCost,
        vatAmount,
        subTotalPrice,
        totalPrice,

        transportCost: 0,
        additionalServicesCost: 0,
        ecadisCountryId: auction.ecadisCountryId,
        carOriginCountryId: auction.carOriginCountryId,
        sellerCountryId: auction.sellerCountryId,
        //isEx1Allowed: auction.sellerCountryId === "nl" || auction.sellerCountryId === "be",
        isEx1Allowed: auction.exportNonEU && auction.exportNonEU.eX1Document && auction.exportNonEU.eX1Document.isAvailable,

        isCopyCarDocMandatory: !!auction.copyCarDocMandatory,

        // VAT related ...
        vatPercentage: auction.vatPercentage,
        isMarginCar: auction.carIdentification.isMargin,
        isInvoiceOfficeEurope: auction.isInvoiceOfficeEurope,
        isEuBuyer: auction.isEuBuyer,
        buyerCountry: auction.buyerCountryId,

        isLocalSale: !auction.isInvoiceOfficeEurope,
        isIntraCommunautaireSale: auction.isInvoiceOfficeEurope && auction.isEuBuyer,
        isExportSale: auction.isInvoiceOfficeEurope && !auction.isEuBuyer,
        currencyCode: auction.currencyCodeId, //OrderCompletion page
    };
}

/*

PriceInfo: 

    CurrentPrice: 1300
    Increment: 100
    NextMaxPrice: 1300
    TargetPrice: -1
    BuyNowPrice: 1300
    IsMagrin: false
    IsBlind: false
    HasUserPlacedABid: true
    IsUserHighestBidder: true
    BidderHigherBidAmount: 0
    ShowCountryCost: true
    CountryCost: 100
    ShowAuctionFee: true
    AuctionFee: 20
    ShowBpm: false
    BpmAmount: 0
    SubTotal: 1420
    ShowVat: true
    VatAmount: 298.2
    TotalPrice: 1718.2
    CurrencyCode: "EUR"
    ShowConvertedInfo: false
    ConvertedCurrentPrice: 0
    ConvertedCountryCost: 0
    ConvertedAuctionFee: 0
    ConvertedBpmAmount: 0
    ConvertedSubTotal: 0
    ConvertedVatAmount: 0
    ConvertedTotalPrice: 0
    ExchangeRate: 0
    IsBidWithdrawAllowed: true
    ShowBpmDeregistrationToolTip: false
*/

//======================================================================================
/*

Delivery options examples:

[
    {
        "Destination": "LocalDelivery",
        "Price": 564.0,
        "MinDate": "29/05/2019",
        "MaxDate": "13/06/2019",
        "IsOptimo": false,
        "IsOptimoFinAvailable": false
    },
    {
        "Destination": "HubDelivery",
        "Price": 523.0,
        "MinDate": "05/06/2019",
        "MaxDate": "18/06/2019",
        "IsOptimo": false,
        "IsOptimoFinAvailable": false
    },
    {
        "Destination": "HubDelivery",
        "Price": 568.00,
        "MinDate": "30/05/2019",
        "MaxDate": "13/06/2019",
        "IsOptimo": true,
        "IsOptimoFinAvailable": false
    },
    {
        "Destination": "LocalDelivery",
        "Price": 613.00,
        "MinDate": "30/05/2019",
        "MaxDate": "13/06/2019",
        "IsOptimo": true,
        "IsOptimoFinAvailable": false
    },
    {
        "Destination": "OwnTransport",
        "Price": 40.0,
        "MinDate": "22/05/2019",
        "MaxDate": "27/05/2019",
        "IsOptimo": false,
        "IsOptimoFinAvailable": false
    }
]

Result: 

        {
            id: 1,
            title: "Delivery to your address",
            detail: "Estimated: 24-26 March",
        },
        {
            id: 2,
            title: "Pick up at local hub",
            detail: "Estimated: 24-26 March",
        },
        {
            id: 3,
            title: "Pick up at Belgian hub",
            detail: "Estimated: 24-26 March",
            price: 25,
        }



*/

const destinationToTitleMapping = {
    LocalDelivery: 'orderCompletion.options.transportMode.LocalDelivery',
    OwnTransport: 'orderCompletion.options.transportMode.OwnTransport',
    HubDelivery: 'orderCompletion.options.transportMode.HubDelivery',
    OtherAddressDelivery: 'orderCompletion.options.transportMode.OtherAddressDelivery',
    OwnTransportSeller: 'orderCompletion.options.transportMode.OwnTransportSeller',
};

export function deliveryOptionsToState(options) {
    const filtered = options.filter(o => !o.IsOptimo && !o.IsFin);

    return filtered.map(o => ({
        id: o.Destination,
        title: destinationToTitleMapping[o.Destination],
        detail: minMaxDateFormatted(o.MinDate, o.MaxDate),
        ...transportPriceDetails(o.DisplayPrice, o.PriceLines, o.Reduction),
        deliveryAddressId: o.deliveryAddressId,
        displayAddress:
            o.Destination === 'OtherAddressDelivery'
                ? i18n.t('orderCompletion.options.transportMode.OtherAddressDeliveryText')
                : o.DisplayAddress || '',
        discount: o.CountryCostDiscount,
    }));
}

export const isOptimoSelected = options => options.selectedFinancialService === 'OPTIMO' || options.selectedFinancialService === 'OPTIMO_FIN';

export const isFinSelected = options => options.selectedFinancialService === 'FIN';

export function minMaxDateFormatted(minDate, maxDate) {
    if (!minDate || !maxDate) return null;

    const minDateM = parseDate(minDate, 'short');
    const maxDateM = parseDate(maxDate, 'short');

    // display either "28 May - 3 Jun" (different months) or "3 - 10 Jun" (same month)
    const minMonth = i18n.t(`common.month${formatDate(minDateM, 'MM')}Short`);
    const maxMonth = i18n.t(`common.month${formatDate(maxDateM, 'MM')}Short`);
    const minDay = formatDate(minDateM, 'd');
    const maxDay = formatDate(maxDateM, 'd');
    const dateRange = minMonth !== maxMonth ? `${minDay} ${minMonth} - ${maxDay} ${maxMonth}` : `${minDay} - ${maxDay} ${maxMonth}`;
    //return withLabel ? `${i18n.t('orderCompletion.common.estDelivery')}: ${dateRange}` : dateRange;
    return dateRange;
}

const round = amount => Math.round(amount * 100) / 100;
const vatAmount = (baseAmount, vatPercentage) => round((baseAmount * vatPercentage) / 100);

/** ===============================================================================================
 *
 * Calculate totals based on selected options
 *
 * To be displayed in summary block.
 *
 * @param {*} options
 */

export function getTotalOfPriceLines(priceLines) {
    return priceLines.reduce((a, b) => a + b.Price, 0);
}
export function getTotalReductionOfPriceLines(priceLines) {
    return priceLines.reduce((a, b) => a + b.Reduction, 0);
}

export function summaryFromSelectedOptions(options, summary, auction) {
    return options.isNonTransactionalCompletion
        ? summaryFromSelectedOptionsForNonTransactional(options, summary, auction)
        : summaryFromSelectedOptionsForAdesaAuctions(options, summary, auction);
}

export function summaryFromSelectedOptionsForNonTransactional(options, summary, auction) {
    let toPayVat = 0;
    let vatAppliedToTransportAndOptimo = false;
    //if (auction.isLocalSale) {
    if (auction.buyerCountry == 'be') {
        // VAT only for BE buyer ! cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/571211
        vatAppliedToTransportAndOptimo = true;
        toPayVat = vatAmount(summary.auctionFee, auction.vatPercentage);
    }
    const toPayNow = summary.auctionFee + toPayVat;

    const priceLines = [{ Title: 'AUCTIONFEE', Price: summary.auctionFee, Reduction: 0 }];

    return {
        isTransportCostExcluded: true,
        transportCost: 0,
        isOwnTransport: true,
        additionalServicesCost: 0,
        buyerPromos: 0,
        showOptimo: false,
        isOptimoFin: false,
        otherCosts: [],
        showFin: false,
        subTotal: 0,
        localVat: 0,
        appliedVat: 0,
        total: toPayNow,
        warrantyForExport: 0,
        vatAppliedToTransportAndOptimo,
        priceLines,
        toPayNow,
        toPayRemaining: 0,
        toPayVat,
    };
}

export function summaryFromSelectedOptionsForAdesaAuctions(options, summary, auction) {
    const minWarranty = 500;
    const otherCosts = [];
    // calculate prices for selected services...
    let transportCost = 0;
    let countryCostDiscount = 0;
    if (options.selectedTransportMode && options.transportModes.length > 0) {
        transportCost = options.transportModes.filter(a => a.id === options.selectedTransportMode)[0].reducedPrice;
        countryCostDiscount = options.transportModes.filter(a => a.id === options.selectedTransportMode)[0].discount;
        summary.countryCostDiscount = countryCostDiscount;
        auction.allFees = summary.countryCost + summary.auctionFee + summary.sellerSpecificFee + summary.bpmFee + summary.countryCostDiscount;
    }
    let financialServicePriceLines = [];
    if (options.selectedFinancialService) {
        financialServicePriceLines = options.financialServices.find(a => a.id === options.selectedFinancialService).priceLines;
    }

    let additionalServicesCost = 0;
    if (options.additionalServices.length > 0) {
        const selection = options.additionalServices.filter(a => options.selectedAdditionalServices.indexOf(a.id) > -1);
        additionalServicesCost += selection.map(a => a.price).reduce((sum, a) => sum + a, 0);
        otherCosts.push(...selection.map(a => ({ label: a.title, price: a.price })));
    }

    let buyerPromos = 0;
    if (options.buyerPromos.length > 0) {
        buyerPromos += options.buyerPromos
            .filter(a => options.selectedBuyerPromos.indexOf(a.id) >= 0)
            .map(a => a.price)
            .reduce((sum, a) => sum + a, 0);
    }

    if (!options.isEu && auction.isEx1Allowed && options.selectedNonEuOptions.length > 0) {
        const selection = options.nonEuOptions.filter(a => options.selectedNonEuOptions.indexOf(a.id) > -1);
        additionalServicesCost += selection.reduce((sum, a) => sum + a.price, 0);
        otherCosts.push(...selection.map(a => ({ label: a.title, price: a.price })));
    }
    const allServices =
        transportCost +
        getTotalOfPriceLines(financialServicePriceLines) -
        getTotalReductionOfPriceLines(financialServicePriceLines) +
        additionalServicesCost;

    let subTotal = Math.max(
        0,
        summary.vehiclePrice +
            summary.countryCost +
            summary.auctionFee +
            summary.sellerSpecificFee +
            summary.dealerDiscount +
            summary.countryCostDiscount +
            allServices -
            buyerPromos -
            summary.auctionFeeDiscount,
    );

    // VAT calculation (https://karauctionservices.visualstudio.com/International/_workitems/edit/76933)
    let localVat = 0; // for regular cars.
    let appliedVat = 0; // for margin cars
    let warrantyForExport = 0;

    let vatAppliedToTransportAndOptimo = false;

    let toPayVat = 0;
    let total = 0;
    let fees = options.selectedTransportMode === 'OwnTransportSeller' ? auction.specificFees : auction.allFees;

    if (options.isUkOrderCompletion) {
        // take values from priceinfoservice.
        localVat = auction.vatAmount;
        total = auction.totalPrice;
        subTotal = auction.subTotalPrice;
    } else {
        if (auction.isMarginCar) {
            // Margin car.
            if (auction.isLocalSale) {
                vatAppliedToTransportAndOptimo = true;
                appliedVat = vatAmount(fees + allServices, auction.vatPercentage);
                toPayVat = vatAmount(
                    transportCost + getTotalOfPriceLines(financialServicePriceLines) - getTotalReductionOfPriceLines(financialServicePriceLines),
                    auction.vatPercentage,
                );
            }
            if (auction.isIntraCommunautaireSale) {
                const isOptimo = isOptimoSelected(options);
                if (isOptimo) {
                    // https://karauctionservices.visualstudio.com/International/_workitems/edit/102023
                    appliedVat = vatAmount(fees + additionalServicesCost, auction.vatPercentage);
                } else {
                    appliedVat = vatAmount(fees + allServices, auction.vatPercentage);
                }
            }
            if (auction.isExportSale) {
                appliedVat = 0;
                warrantyForExport = Math.max(minWarranty, vatAmount(subTotal, 21));
            }
        } else {
            // Regular Car
            if (auction.isLocalSale) {
                vatAppliedToTransportAndOptimo = true;
                localVat = vatAmount(subTotal, auction.vatPercentage);
                // console.log(
                //   transportCost +
                //     getTotalOfPriceLines(financialServicePriceLines) -
                //     getTotalReductionOfPriceLines(financialServicePriceLines)
                // );
                toPayVat = vatAmount(
                    transportCost + getTotalOfPriceLines(financialServicePriceLines) - getTotalReductionOfPriceLines(financialServicePriceLines),
                    auction.vatPercentage,
                );
            }
            if (auction.isIntraCommunautaireSale) {
                localVat = 0;
            }
            if (auction.isExportSale) {
                localVat = 0;
                warrantyForExport = Math.max(minWarranty, vatAmount(subTotal, 21));
            }
        }
        subTotal = subTotal + (summary.bpmFee || 0); // !! no VAT for bpmFee
        total = subTotal + (localVat + appliedVat + warrantyForExport);
    }

    const toPayNow =
        isOptimoSelected(options) || isFinSelected(options)
            ? transportCost + getTotalOfPriceLines(financialServicePriceLines) - getTotalReductionOfPriceLines(financialServicePriceLines) + toPayVat
            : 0; // pay only optimo + transport
    const toPayRemaining = total - toPayNow;

    return {
        isTransportCostExcluded: options.isUkOrderCompletion && options.selectedTransportMode !== 'HubDelivery',
        transportCost,
        isOwnTransport: options.selectedTransportMode === 'OwnTransport', // display other label.
        additionalServicesCost,
        mechanicalIndicatorFee: auction.mechanicalIndicatorFee,
        buyerPromos,
        showOptimo: isOptimoSelected(options), // show optimo even if 0 after reductions (https://karauctionservices.visualstudio.com/International/_workitems/edit/93099)
        isOptimoFin: options.selectedFinancialService === 'OPTIMO_FIN',
        otherCosts, // array, show additional service costs separately (https://karauctionservices.visualstudio.com/International/_workitems/edit/93096)
        showFin: isFinSelected(options),
        subTotal,
        localVat,
        mechanicalIndicatorFeeVat: auction.mechanicalIndicatorFeeVat,
        appliedVat,
        total,
        warrantyForExport,
        vatAppliedToTransportAndOptimo,
        priceLines: financialServicePriceLines,
        toPayNow,
        toPayRemaining,
        toPayVat,
        countryCostDiscount,
    };
}

/**
 *  data: {"AuctionId":3525309,"CarsToComplete":3,"NextCompletionBeforeDate":"2019-06-04T10:33:47.273"}
 *
 *
 * @param {*} data
 */
export function nextStepsToState(data) {
    return {
        nextAuctionId: data.auctionId,
        nbCarsToComplete: data.carsToComplete,
        nextCompletionBefore: data.nextCompletionBeforeDate ? parseDate(belgiumDateTimeToLocal(data.nextCompletionBeforeDate)) : null,
        //nextCompletionBefore: moment('2020-02-04T10:30:00'),
    };
}

/*

    BankAccountDetails = new BankAccountDetails {
        BankName = "Bart's Bank",
        BIC = "BIC Nr 1",
        IBAN = "IBAN"
    },
    CarLocationDetail = new CarLocationDetail {
        CurrentCountry = "BE",
        CurrentLocation = "My Location",
        Destination = "Car Destination",
        EstimatedDeliveryDateMax = DateTime.Today.AddDays(6),
        EstimatedDeliveryDateMin = DateTime.Today.AddDays(3)
    },
    OrderConfirmationSentTo = "giovanni.boscarino@adesa.eu",
    PaymentDueDate = DateTime.Today.AddDays(2)

*/
export function paymentDetailsToState(data, auctionId, summary) {
    const dueDate = parseDate(belgiumDateTimeToLocal(data.PaymentDueDate));
    const dueDateDiff = differenceInMilliseconds(dueDate, getCurrentDate());

    const deliveryMin = formatDate(belgiumDateTimeToLocal(data.CarLocationDetail.EstimatedDeliveryDateMin, 'dd/MM'));
    const deliveryMax = formatDate(belgiumDateTimeToLocal(data.CarLocationDetail.EstimatedDeliveryDateMax, 'dd/MM'));

    const formattedAuctionId = pad(auctionId, 12, '0');

    return {
        bankName: data.BankAccountDetails.BankName || '-',
        bankIban: data.BankAccountDetails.IBAN || '-',
        bankBic: data.BankAccountDetails.BIC || '-',
        amountToPay: summary.toPayRemaining,
        //communication: `+++${formattedAuctionId.substr(0,3)}/${formattedAuctionId.substr(3,4)}/${formattedAuctionId.substr(7)}+++`,
        communication: auctionId.toString(),
        remainingHours: 48, //Math.ceil(dueDateDiff.asHours()), // hotfix: always 48h, data.PaymentDueDate is used only to block when no payment.

        currentLocation: data.CarLocationDetail.CurrentLocation,
        destination: data.CarLocationDetail.Destination,
        estimatedDeliveryPeriod: minMaxDateFormatted(deliveryMin, deliveryMax),
        orderSentToEmail: data.OrderConfirmationSentTo,
    };
}

export function paymentStatusFromString(status) {
    switch (status) {
        case 'exception':
            return PAYMENT_STATUS.FAILED;
        case 'declined':
            return PAYMENT_STATUS.FAILED;
        case 'cancelled':
            return PAYMENT_STATUS.FAILED;
        //case "": return PAYMENT_STATUS.NONE;
        case 'accepted':
            return PAYMENT_STATUS.PENDING;
        case 'pending':
            return PAYMENT_STATUS.PENDING;
        //case "accepted": return PAYMENT_STATUS.SUCCESS;
    }
}

export function paymentStatusFromCode(status) {
    /*
    public enum PaymentStatus
    {
      Pending = 0,
      Accepted = 1,
      Cancelled = 2,
      Declined = 3,
      Exception = 4,
      Unknown = 99, // 0x00000063
      InvalidResponse = 100, // 0x00000064
    }
*/
    switch (status) {
        case 0:
            return { status: PAYMENT_STATUS.PENDING, message: '' };
        case 1:
            return { status: PAYMENT_STATUS.SUCCESS, message: '' };
        case 2:
            return { status: PAYMENT_STATUS.FAILED, message: 'Payement cancelled' };
        case 3:
            return { status: PAYMENT_STATUS.FAILED, message: 'Payment declined' };
        case 4:
            return { status: PAYMENT_STATUS.FAILED, message: 'Payement error' };
        default:
            return { status: PAYMENT_STATUS.FAILED, message: 'Invalid payment' };
    }
}

import { currencyFormat, mileageFormat, range, formatNumber, extrapolate } from '../utils';
import i18n from '../i18n';
import { addSeconds, isAfter, isBefore } from '../utils/date';
import { getCurrentDate } from '../utils/date';

export function batchTypeFromBatchAttributeId(isBuyNow, batchAttributeId) {
    /*
        model.BatchAttributeId:
            1	No Special Option
            2	Buy Now
            3	Buy Now and Pick Up
            4	Blind
            5	Target
    */
    return {
        isBuyNow: isBuyNow ? true : batchAttributeId === 2 || batchAttributeId === 3 ? true : false,
        isDynamic: batchAttributeId === 1,
        isTarget: isBuyNow ? false : batchAttributeId === 5, // alphabet batch can be target but one specific auction can be buyNow.
        isBlind: batchAttributeId === 4,
    };
}

export const STEPWISE_BID_METHOD = 1;
export const MAXIMUM_BID_METHOD = 2;

/*
 * Maps categoryId to translation key
 */
const optionCategoriesMapping = {
    1: 'vehicleDetails.equipment.airconditioning',
    2: 'vehicleDetails.equipment.upholstery',
    3: 'vehicleDetails.equipment.communication',
    4: 'vehicleDetails.equipment.confort',
    5: 'vehicleDetails.equipment.security',

    10: 'vehicleDetails.equipment.comfort',
    11: 'vehicleDetails.equipment.exteriorFeatures',
    12: 'vehicleDetails.equipment.finish',
    13: 'vehicleDetails.equipment.multimedia',
    14: 'vehicleDetails.equipment.safetyAndSecurity',
    15: 'vehicleDetails.equipment.services',
    16: 'vehicleDetails.equipment.powertrain',
    17: 'vehicleDetails.equipment.accessories',
    18: 'vehicleDetails.equipment.wheelsAndTires',
    19: 'vehicleDetails.equipment.packs',
    20: 'vehicleDetails.equipment.additionalOptions',
};

export const optionCategoriesReducer = (hash, v) => {
    if (!hash) hash = {};
    const label = optionCategoriesMapping[v.CategoryId];
    if (!hash[label]) hash[label] = [];
    hash[label].push(v.Value);
    return hash;
};

export const followNotificationMessage = following => ({
    message: following ? i18n.t('toast.flashing.vehicleAddedToFollowedCars') : i18n.t('toast.flashing.VehicleRemovedFromFollowedCars'),
    status: following ? 'success' : 'info',
    dismissible: true,
    closeButton: true,
    dismissAfter: 1500,
});

export function tooHighBidAmount(inputPrice, maximumBid, minimumBidForRspCheck) {
    if (maximumBid > 0) {
        if (inputPrice > minimumBidForRspCheck) {
            if (inputPrice > maximumBid) return true;
        }
    }
    return false;
}

// checks if the list of accessories contains electric cables.
export function hasEletricCables(accessories) {
    const loadingCable1StockItemId = 34;
    const loadingCable2StockItemId = 35;
    const ids = accessories.map(a => a.stockItemTypeId);
    return ids.indexOf(loadingCable1StockItemId) > -1 || ids.indexOf(loadingCable2StockItemId) > -1;
}

// export const prices = range(1,50).map(p => ({id: p*1000 , name: currencyFormat(p*1000)}));
const pricesList = [
    1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 25000, 30000,
    35000, 40000, 45000, 50000,
];
export const prices = pricesList.map(p => ({ id: p, name: currencyFormat(p) }));

//export const mileages = [5000, ...range(1,10).map(a => a*10000), 125000, 150000, 175000 ].map(p => ({id: p , name: mileageFormat(p)}));
const mileagesList = [50, 5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 125000, 140000, 150000, 175000, 200000];
export const mileages = t => mileagesList.map(p => ({ id: p, name: `${formatNumber(p)}` }));

const currentYear = new Date().getFullYear();
//export const years = range(1, 10).map(i => ({id: i, name: (currentYear - 10 + i).toString() })).reverse();
export const years = range(1, 10)
    .map(i => currentYear - 10 + i)
    .map(y => ({ id: y, name: y.toString() }))
    .reverse();

const engineSizesList = [500, 1000, 1500, 2000, 2500, 3000, 3500, 4000];
export const engineSizes = t => engineSizesList.map(p => ({ id: p, name: `${formatNumber(p)} ${t('common.unit.cc')}` }));

const horsePowersList = [50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 175, 200, 225, 250];
export const horsePowers = t => horsePowersList.map(p => ({ id: p, name: `${p.toString()} ${t('common.units.hp')}` }));

const kWsList = [25, 37, 44, 55, 66, 74, 87, 96, 110, 147, 185, 223, 263, 296];
export const kWs = t => kWsList.map(p => ({ id: p, name: `${p.toString()} ${t('common.units.kw')}` }));

/**
 * Returns the translation to be used for EtgFuelId
 *
 * Available translations:
 *      fuelType.lpg
 *      fuelType.diesel
 *      fuelType.benzine
 *      fuelType.hybride
 *      fuelType.electric
 *      fuelType.hydrogen
 *
 */
export const fuelTypesTranslation = {
    100001: 'fuelType.benzine', // Petrol unleaded
    100002: 'fuelType.benzine', // Petrol leaded
    100003: 'fuelType.diesel', // Diesel
    100004: 'fuelType.electric', // Electric (Battery)
    100005: 'fuelType.hybride', // Fuel cell
    100006: 'fuelType.benzine', // Petrol or gas
    100007: 'fuelType.diesel', // Diesel or gas
    100008: 'fuelType.hydrogen', // Hydrogen
    100009: 'fuelType.hybride', // Alcohol
    100010: 'fuelType.benzine', // Two stroke mixture
    100011: 'fuelType.lpg', // Gas
    100012: 'fuelType.benzine', // Petrol super unleaded
    100013: 'fuelType.hybride', // Petrol/Electric
    100022: 'fuelType.hybride', // Diesel/Electic
    100023: 'fuelType.hybride', // Petrol/Electric (Plug-in)
    100024: 'fuelType.hybride', // Diesel/Electric (Plug-in)
    100099: 'fuelType.benzine', // Euro standard fuels
    107003: 'fuelType.hybride', // CNG
    107004: 'fuelType.benzine', // Petrol CNG
    107005: 'fuelType.diesel', // Diesel CNG
    107006: 'fuelType.diesel', // Diesel LPG
};

/**
 *  From raw barometer prices received from the server
 *         biddingAdvisor: {
 *                  barometerPrices: [{Price: 1234, YHat: 0.4, Sequence: 1}, ...],
 *                  barometerPricesRecommended: {MinIndex: 1, MaxIndex: 11, RecommendedIndex: 8}
 *          }
 *
 *  returns data structure necessary to draw the barometer:
 *      {
 *          recommended,    // Recommended price
 *          bottom,         // price at the bottom of the barometer (price to show when slider at the bottom)
 *          top,            // price at the top of the baromter (price to show when slider at the top)
 *          group,          // [1..4] indicate which kind of gradiant color to apply (probability of Recommended price)
 *                          // 1 = more red (low probability), 4 = more green (high probability)
 *                          // cfr https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/3277
 *       }
 *
 *
 * @param {*} biddingAdvisor
 */
export function priceBarometerDisplayParameters(biddingAdvisor) {
    /*
        cfr PriceBarometerRepository.GetPriceBarometerRecommendedPrice (in AuctionService) for data related to
        biddingAdvisor.barometerPricesRecommended
    */

    // { MinIndex: 1, MaxIndex: 15, RecommendedIndex: 10 }
    const bottom = Math.max(0, Math.min(biddingAdvisor.barometerPrices.length - 1, biddingAdvisor.barometerPricesRecommended.MinIndex - 1));
    const top = Math.min(biddingAdvisor.barometerPrices.length - 1, biddingAdvisor.barometerPricesRecommended.MaxIndex - 1);
    const recommended = Math.min(biddingAdvisor.barometerPrices.length - 1, biddingAdvisor.barometerPricesRecommended.RecommendedIndex - 1);

    // cfr https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/3277
    const refYhat = biddingAdvisor.barometerPrices[recommended].YHat;
    const group = refYhat <= 0.25 ? 1 : refYhat <= 0.5 ? 2 : refYhat <= 0.75 ? 3 : 4;
    return {
        recommended: biddingAdvisor.barometerPrices[recommended].Price,
        top: biddingAdvisor.barometerPrices[top].Price,
        bottom: biddingAdvisor.barometerPrices[bottom].Price,
        group,
    };
}

export function sellCarsLink(language) {
    const newLanguages = ['it', 'en', 'de', 'fr-fr', 'fr-be', 'nl-nl', 'nl-be'];
    const isPageAvailableInLanguage = newLanguages.indexOf(language.toLowerCase()) > -1;
    return `https://sellers.openlane.eu/${isPageAvailableInLanguage ? language : 'en'}/buy-sell-with-ease/`;
}

// see https://karauctionservices.visualstudio.com/International/_workitems/edit/214201
// for LP&GWListe, we add 10 sec. delay to give a chance to receive the 'extend' event before showing the
// auction as closed.
const maxWaitingTimeInSecs = 10;
export const isLeasePlanClosed = endDate => isBefore(addSeconds(endDate, maxWaitingTimeInSecs), getCurrentDate());
export const isLeasePlanWaitingExtend = endDate => {
    const now = getCurrentDate();
    return isBefore(endDate, now) && isAfter(addSeconds(endDate, maxWaitingTimeInSecs), now);
};

export const EXTENDED_PHASE_STATUS = Object.freeze({
    PREPARING: 1,
    INQUEUE: 2,
    INXTIME: 3,
    CLOSED: 4,
});

export const AUCTION_STATUS = Object.freeze({
    TO_BE_APPROVED: 1,
    APPROVED: 2,
    NOT_APPROVED: 3,
    WAITING_FOR_ANSWER: 4,
    SELLER_AGREE: 5,
    SELLER_NOT_AGREE: 6,
    CONFIRMED: 7,
    PAID: 8,
    PUA_READY: 9,
    PUA_SENT: 10,
    FDC_RECEIVED: 11,
    FDC_APPROVED: 12,
    RETURNED_TO_SELLER: 13,
    CANCELLED: 14,
    FDC_DIS_APPROVED: 15,
    BUY_NOW: 40,
    BUY_NOW_CONFIRMED: 41,
    AUCTION_WITHDRAWN: 50,
    PROVISIONAL: 30,
    PROVISIONAL_PENDING_SELLER: 31,
    PROVISIONAL_PENDING_BUYER: 32,
    NON_TRANSACTIONAL_PAID: 130,
});

export const CONFIRMED_AUCTION_STATUSES = [
    AUCTION_STATUS.CONFIRMED,
    AUCTION_STATUS.PAID,
    AUCTION_STATUS.PUA_READY,
    AUCTION_STATUS.PUA_SENT,
    AUCTION_STATUS.FDC_RECEIVED,
    AUCTION_STATUS.FDC_APPROVED,
    AUCTION_STATUS.FDC_DIS_APPROVED,
    AUCTION_STATUS.BUY_NOW_CONFIRMED,
];

export const isInProvisionalState = status =>
    [AUCTION_STATUS.PROVISIONAL, AUCTION_STATUS.PROVISIONAL_PENDING_SELLER, AUCTION_STATUS.PROVISIONAL_PENDING_BUYER].indexOf(status) > -1;

export const ADDRESS_TYPE = Object.freeze({
    MAIN: 1,
    INVOICE: 2,
    DELIVERY: 3,
    PICKUP_AND_TRANSPORT: 4,
    HUB: 5,
    CENTRAL_PICKUP: 6,
    PICKUP_ONLY: 7,
    POSTAL: 8,
});

export const PAYMENT_STATUS = Object.freeze({
    NONE: 0,
    PENDING: 1,
    SUCCESS: 2,
    FAILED: 3,
});

//Vehical Grades Mapping -------
export const vehicleGradesMetadata = [
    {
        id: 1,
        label: 'vehicleDetails.vehicleGrades.gradeHeadingtxt1',
        iconColor: 'grade-icon-green',
        subHeading: 'vehicleDetails.vehicleGrades.gradeSubtxt1',
        description: 'vehicleDetails.vehicleGrades.grade1',
    },
    {
        id: 2,
        label: 'vehicleDetails.vehicleGrades.gradeHeadingtxt2',
        iconColor: 'grade-icon-lime',
        subHeading: 'vehicleDetails.vehicleGrades.gradeSubtxt2',
        description: 'vehicleDetails.vehicleGrades.grade2',
    },
    {
        id: 3,
        label: 'vehicleDetails.vehicleGrades.gradeHeadingtxt3',
        iconColor: 'grade-icon-yellow',
        subHeading: 'vehicleDetails.vehicleGrades.gradeSubtxt3',
        description: 'vehicleDetails.vehicleGrades.grade3',
    },
    {
        id: 4,
        label: 'vehicleDetails.vehicleGrades.gradeHeadingtxt4',
        iconColor: 'grade-icon-orange',
        subHeading: 'vehicleDetails.vehicleGrades.gradeSubtxt4',
        description: 'vehicleDetails.vehicleGrades.grade4',
    },
    {
        id: 5,
        label: 'vehicleDetails.vehicleGrades.gradeHeadingtxt5',
        iconColor: 'grade-icon-red',
        subHeading: 'vehicleDetails.vehicleGrades.gradeSubtxt5',
        description: 'vehicleDetails.vehicleGrades.grade5',
    },
    {
        id: 99,
        label: 'vehicleDetails.vehicleGrades.gradeHeadingtxt99',
        iconColor: 'grade-icon-silver',
        subHeading: 'vehicleDetails.vehicleGrades.gradeSubtxt99',
        description: 'vehicleDetails.vehicleGrades.unclassified.description',
    },
];

// --------Mechanical Indicator--Mapping------
export const mechanicalIndicatorsMetadata = [
    {
        id: 1,
        label: 'vehicleDetails.mechanicalIndicators.noIndicator',
        iconColor: 'grade-icon-silver',
        name: 'N',
        description: 'vehicleDetails.profile.mechanicalIndicators.desc1',
    },
    {
        id: 2,
        label: 'vehicleDetails.mechanicalIndicators.majorMechanicalFault',
        iconColor: 'grade-icon-cherry-red',
        name: 'R',
        description: 'vehicleDetails.profile.mechanicalIndicators.desc2',
    },
    {
        id: 3,
        label: 'vehicleDetails.mechanicalIndicators.noMajor',
        iconColor: 'grade-icon-green',
        name: 'G',
        description: 'vehicleDetails.profile.mechanicalIndicators.desc3',
    },
    {
        id: 4,
        label: 'vehicleDetails.mechanicalIndicators.advisory',
        iconColor: 'grade-icon-orange',
        name: 'A',
        description: 'vehicleDetails.profile.mechanicalIndicators.desc4',
    },
];

// spareTyres Mapping ---------
export const spareTyres = {
    1: 'vehicleDetails.profile.spareTyres.none',
    2: 'vehicleDetails.profile.spareTyres.inflation',
    3: 'vehicleDetails.profile.spareTyres.spaceSaver',
    4: 'vehicleDetails.profile.spareTyres.fullSize',
};

// --------Inventory Type--Mapping------
export const inventoryTypeMapping = {
    1: 'vehicleDetails.profile.inventoryType1',
    2: 'vehicleDetails.profile.inventoryType2',
    3: 'vehicleDetails.profile.inventoryType3',
    4: 'vehicleDetails.profile.inventoryType4',
    5: 'vehicleDetails.profile.inventoryType5',
    6: 'vehicleDetails.profile.inventoryType6',
    7: 'vehicleDetails.profile.inventoryType7',
    8: 'vehicleDetails.profile.inventoryType8',
    9: 'vehicleDetails.profile.inventoryType9',
};

// LanguageMetaDataMeta
export const languageOptionsMeta = [
    { id: 'bg', label: 'българѝки', value: 'bg' },
    { id: 'cs', label: 'Česky', value: 'cs' },
    { id: 'de', label: 'Deutsch', value: 'de' },
    { id: 'en', label: 'English', value: 'en' },
    { id: 'es', label: 'Español', value: 'es' },
    { id: 'fr-be', label: 'Français (Belgique) ', value: 'fr-be' },
    { id: 'fr-fr', label: 'Français (France)  ', value: 'fr-fr' },
    { id: 'nl-be', label: 'Nederlands (België) ', value: 'nl-be' },
    { id: 'nl-nl', label: 'Nederlands (Nederland)  ', value: 'nl-nl' },
    { id: 'hr', label: 'Hrvatski', value: 'hr' },
    { id: 'hu', label: 'Magyar', value: 'hu' },
    { id: 'it', label: 'Italiano', value: 'it' },
    { id: 'lt', label: 'Lietuvių', value: 'lt' },
    { id: 'pl', label: 'Polski', value: 'pl' },
    { id: 'pt', label: 'Portuguese', value: 'pt' },
    { id: 'ro', label: 'Romanian', value: 'ro' },
    { id: 'ru', label: 'Руѝѝкий', value: 'ru' },
    { id: 'sl', label: 'Slovenšĝina', value: 'sl' },
    { id: 'sk', label: 'Slovenský', value: 'sk' },
    { id: 'fi', label: 'Suomi', value: 'fi' },
    { id: 'tr', label: 'Türkçe', value: 'tr' },
    { id: 'el', label: 'Ελληνικά', value: 'el' },
];

//---Provisional states-------
export const PROVISIONAL_STATES = Object.freeze({
    PROPOSAL: 'Proposal',
    COUNTER_OFFER_SELLER: 'CounterOfferSeller',
    COUNTER_OFFER_BUYER: 'CounterOfferBuyer',
    STAND_ON_SELLER: 'StandOnSeller',
    STAND_ON_BUYER: 'StandOnBuyer',
    REJECTED_SELLER: 'RejectedSeller',
    REJECTED_BUYER: 'RejectedBuyer',
    ACCEPTED_SELLER: 'AcceptedSeller',
    ACCEPTED_BUYER: 'AcceptedBuyer',
    COMPLETED: 'Completed',
});

export const PROVISIONAL_ACTIONS = Object.freeze({
    ACCEPT: 'Accept',
    REJECT: 'Reject',
    COUNTER_OFFER: 'CounterOffer',
    STAND_ON_OFFER: 'StandOnOffer',
});

const ECADIS_COUNTRY_UK = 9;
export const isUkEcadisCountry = ecadisCountry => ecadisCountry === ECADIS_COUNTRY_UK;

// BodyTypes size Mappings ---------
export const bodyTypesMapping = [
    { label: 'suv', value: 'suv' },
    { label: 'sportsutilityvehicle', value: 'sports utility vehicle (suv)' },
    { label: 'stationwagon', value: 'station wagon' },
    { label: 'berline', value: 'berline' },
    { label: 'break', value: 'break' },
    { label: 'carbriolet', value: 'carbriolet' },
    { label: 'compact', value: 'compact' },
    { label: 'mpv', value: 'mpv' },
    { label: 'lighttruck', value: 'light truck' },
    { label: 'hatchback', value: 'hatchback' },
    { label: 'truck', value: 'truck' },
    { label: 'minibus', value: 'minibus' },
    { label: 'motorbike', value: 'motorbike' },
    { label: 'trailer', value: 'trailer' },
    { label: 'motorbike', value: 'motorbike' },
    { label: 'trailer', value: 'trailer' },
    { label: 'multipurposevehicle', value: 'multi purpose vehicle (mpv)' },
    { label: 'carbriolet', value: 'cabrio car' },
    { label: 'coupé', value: 'coupé' },
    { label: 'lighttruck', value: 'lighttruck' },
    { label: 'coupe', value: 'coupe' },
];

export function registrationDocumentAvailability(val) {
    switch (val) {
        case 0:
            return 'common.value.missing';
        case 1:
            return 'common.value.missingPart1';
        case 3:
            return 'common.value.missingPart2';
        case 2:
            return 'common.value.complete';
        default:
            return '';
    }
}

export const cleanedPhoneNr = phoneNr => phoneNr.replace(/[\s\-.]/g, '');

const STOCK_247_SALE = 6;
export const is247StockSale = batchAttributeId => batchAttributeId === STOCK_247_SALE;

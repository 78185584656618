import { formatDate } from './utils/date';
import { createObservable } from './utils/observable';

// load appsettings.json from the server. This file is located at the same folder as the main app file (index.js).
const scripts = document.getElementsByTagName('script');
const scriptUrl = Array.from(scripts)
    .map(a => a.src)
    .reverse()
    .filter(a => /\/index/.test(a))[0];
const jsonUrl = (scriptUrl || '').replace(/[^/]+\.js\b.*$/, 'appsettings.json?' + formatDate(new Date(), 'yyyyMMddHH')); // force reload (cfr browser cache).

const __settings = {
    isLoading: true,
    featureFlags: {},
};
export const settingsObservable = createObservable(__settings);
const settings = settingsObservable.observable;
export default settings;

/**
 *  the appsettings.json is loaded as a separate file to be able to change the settings during deployment in TFS.
 */
export function loadSettings() {
    return fetch(jsonUrl, { mode: 'cors' /*, credentials: 'include'*/ })
        .then(resp => resp.json())
        .then(newSettings => {
            //console.log('>>>>>>>>>>> newSettings', newSettings);
            settings.carsiteBaseUrl = newSettings.carsiteBaseUrl || location.origin;
            settings.damageBaseUrl = newSettings.damageBaseUrl;
            settings.similarCarUrl = newSettings.similarCarUrl;
            settings.imagesBaseUrl = newSettings.imagesBaseUrl;
            settings.signalrOld = newSettings.signalrOld;
            settings.signalr = newSettings.signalr;
            settings.marketPricesCountriesToShow = newSettings.marketPricesCountriesToShow;
            settings.thumbnailServiceUrlPrefix = newSettings.thumbnailServiceUrlPrefix;
            settings.promoServiceUrl = newSettings.promoServiceUrl;
            settings.dealerSalesServiceUrl = newSettings.dealerSalesServiceUrl;
            settings.communityImagesBaseUrl = newSettings.communityImagesBaseUrl;
            settings.metadataServiceUrl = newSettings.metadataServiceUrl;
            settings.accountsServiceUrl = newSettings.accountsServiceUrl;
            settings.featureFlags = newSettings.featureFlags;
            settings.useLogrocket = newSettings.useLogrocket;
            settings.featureFlagsServiceUrl = newSettings.featureFlagsServiceUrl;

            if (newSettings.okta) {
                let redirectUri = location.origin + '/login/callback';
                settings.oidc = {
                    clientId: newSettings.okta.clientId,
                    issuer: newSettings.okta.issuer,
                    redirectUri,
                    scopes: ['openid', 'profile', 'email'],
                    pkce: true,
                    disableHttpsCheck: false,
                    useInteractionCode: false,
                };
            }
            // fetch additional feature flags from service.
            return fetch(settings.featureFlagsServiceUrl, {
                mode: 'cors',
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    FlagNames: [
                        'isNonTransactionalEnabled',
                        'isNSAPEnabled',
                        'isRadiusSearchForUkEnabled',
                        'IsNewOrderCompletionEnabled',
                        'IsNewOverdueCheckerEnabled',
                        'IsSegaEnabled',
                        'IsNewFooterEnabled',
                        'IsAggregatedBiddingForLimitedAccessDisabled',
                        'IsAsyncOrderCompletionEnabled',
                    ],
                }),
            })
                .then(resp => resp.json())
                .then(resp => {
                    settings.featureFlags = {
                        ...settings.featureFlags,
                        ...resp.reduce((coll, item) => ({ ...coll, [item.name]: item.isEnabled }), {}),
                    };
                })
                .catch(e => console.error('Error while fetching feature flags', e))
                .finally(() => {
                    settings.isLoading = false;
                });
        });
}
